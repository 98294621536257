import { Box, makeStyles, Paper, Typography, useTheme } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import HorizontalArragement from "components/HorizontalArragement";
import TopicArragement from "components/TopicArragement";
import * as routes from "routes";
import Link from "next/link";
import VerticalArragement from "components/VerticalArragement";
import SizableAvatar from "components/SizableAvatar";
import formatDistance from "date-fns/formatDistanceToNowStrict";
import localeEs from "date-fns/locale/es";
import ChildrenFriendlyButton from "components/ChildrenFriendlyButton";

const useStyles = makeStyles((theme) => ({
  itemBody: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    textAlign: "left",
  },
  innerContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  flexStart: {
    alignItems: "flex-start",
  },
  container: {
    padding: theme.spacing(2),
    width: 300,
    display: "flex",
    flexDirection: "colummn",
  },
}));

const HighlightedPost = ({
  id,
  title,
  subtitle,
  topics = [],
  pretext,
  user,
  createdAt,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const isRatherMember = user?.israthermember;

  return (
    <Paper className={classes.container}>
      <VerticalArragement className={classes.innerContainer}>
        <HorizontalArragement justifyContent="space-between">
          <HorizontalArragement>
            <SizableAvatar size={25} src={user.avatar} isRatherMember={isRatherMember} />
            <Typography variant="caption">
              <Link color={theme.palette.type === "light" ? "primary" : "inherit"} href={routes.PROFILE(user.id)}>
                {user.username}
              </Link>
              {" "}
              -
              {" "}
              {formatDistance(new Date(createdAt), { addSuffix: true, locale: localeEs })}
            </Typography>
          </HorizontalArragement>
        </HorizontalArragement>
        <Link
          href={routes.POST(id)}
          passHref
        >
          <ChildrenFriendlyButton noPadding fullWidth style={{ flexGrow: 1 }}>
            <VerticalArragement>
              <HorizontalArragement className={classes.flexStart} justifyContent="space-between">
                <VerticalArragement>
                  <Box>
                    <Typography className="bold">
                      {title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                    >
                      {subtitle}
                    </Typography>
                  </Box>
                  {pretext && (
                    <Typography variant="caption" color="textSecondary">
                      {`${pretext.slice(0, 200)}...`}
                    </Typography>
                  )}
                </VerticalArragement>
              </HorizontalArragement>
            </VerticalArragement>
          </ChildrenFriendlyButton>
        </Link>
        <TopicArragement topics={topics} />
      </VerticalArragement>
    </Paper>
  );
};

const FeedSkeleton = () => {
  const classes = useStyles();
  return (
    <Box className={classes.item} fullWidth>
      <Skeleton
        variant="circle"
        height={50}
        width={50}
      />
      <Box className={classes.itemBody}>
        <Skeleton width={40} height={10} />
        <Skeleton width={80} height={10} />
      </Box>
      <Skeleton width={30} height={30} />
    </Box>
  );
};

HighlightedPost.Skeleton = FeedSkeleton;

export default HighlightedPost;
