import DefaultSkeleton from "components/DefaultSkeleton";
import { useSession } from "store/Session";

const withResolvedSession = (
  Component,
) => {
  const Skeleton = Component.Skeleton || DefaultSkeleton;

  const ProtectedRoute = (props) => {
    const { isLoggedIn } = useSession();

    if (isLoggedIn === null) return <Skeleton />;
    return <Component {...props} />;
  };

  ProtectedRoute.Layout = Component.Layout;
  return ProtectedRoute;
};

export default withResolvedSession;
