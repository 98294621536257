import { Box, Link, ListItem, ListItemAvatar, ListItemText, Typography, useTheme } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import HorizontalArragement from "components/HorizontalArragement";
import SizableAvatar from "components/SizableAvatar";
import VerticalArragement from "components/VerticalArragement";
import formatDistance from "date-fns/formatDistanceToNowStrict";
import localeEs from "date-fns/locale/es";
import * as routes from "routes";

const KOSItem = ({ post, user, createdAt, text }) => {
  const theme = useTheme();
  return (
    <VerticalArragement>

      <HorizontalArragement>
        <SizableAvatar src={user.avatar} size={25} isRatherMember={user.israthermember} />
        <Box>
          <Typography variant="body2" color="textSecondary">
            <Link color={theme.palette.type === "light" ? "primary" : "inherit"} href={routes.PROFILE(user.id)}>
              {user.username}
            </Link>
            {" "}
            -
            en
            {" "}
            <Link color={theme.palette.type === "light" ? "primary" : "inherit"} href={routes.POST(post.id)}>
              {post?.title.slice(0, 30)}
            </Link>
            ...
          </Typography>
          <Typography variant="caption" display="block" color="textSecondary">
            {formatDistance(new Date(createdAt), { addSuffix: true, locale: localeEs })}
          </Typography>
        </Box>
      </HorizontalArragement>

      <Typography variant="body2" color="textPrimary" style={{ overflowWrap: "break-word" }}>
        {text}
      </Typography>
    </VerticalArragement>
  );
};

const KOSSkeleton = () => (
  <ListItem button>
    <ListItemAvatar>
      <Skeleton variant="circle" height={40} width={40} />
    </ListItemAvatar>
    <ListItemText
      primary={(
        <Skeleton height={8} width={50} />
      )}
      secondary={<Skeleton height={8} width={80} />}
    />
  </ListItem>
);

KOSItem.Skeleton = KOSSkeleton;

export default KOSItem;
