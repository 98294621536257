import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Button, Divider, Grid, Hidden, Paper } from "@material-ui/core";

import BaseScreen from "components/BaseScreen";
import RemoteList from "components/RemoteList";

import withMainLayout from "hocs/withMainLayout";
import Link from "next/link";
import routes from "routes";
import APIEndpoints from "APIEndpoints";
import KOSItem from "components/Home/KOSItem";
import withResolvedSession from "hocs/withResolvedSession";
import { useTranslation } from "i18n";
import HorizontalScroll from "components/HorizontalScroll";
import VerticalArragement from "components/VerticalArragement";
import HighlightedPost from "components/HighlightedPost";
import PostByCategory from "components/PostByCategory";

const useStyles = makeStyles((theme) => ({
  hero: {
    minHeight: 400,
    maxHeight: "40vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  papers: {
    padding: theme.spacing(2),
  },
}));

const Home = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <BaseScreen>
      <VerticalArragement spacing={5}>
        <Box className={classes.hero}>
          <Box>
            <Typography
              variant="h3"
              className="bold"
            >
              {t("HOME_TITLE")}
            </Typography>
            <Typography
              paragraph
              variant="h6"
              color="textSecondary"
            >
              {t("HOME_SUBTITLE")}
            </Typography>
            <Link passHref href={routes.CREATE_POST}>
              <Button
                variant="outlined"
                color="primary"
              >
                {t("WRITE_POST")}
              </Button>
            </Link>
          </Box>
        </Box>
        <Divider />
        <VerticalArragement>
          <Typography variant="h6" paragraph>
            {t("HIGHLIGHTED_POSTS")}
          </Typography>
          <RemoteList
            ContainerElement={HorizontalScroll}
            endpoint={APIEndpoints.POSTS.GET_HIGHLIGHTED}
            Skeleton={HighlightedPost.Skeleton}
            Item={HighlightedPost}
            spacing={3}
          />
        </VerticalArragement>
        <Divider />
        <Grid container spacing={2}>
          <PostByCategory />
          <Hidden xsDown>
            <Grid item sm={4} md={4}>
              <Typography variant="h6" paragraph>
                {t("RELEVANT_THIS_WEEK")}
              </Typography>
              <Paper className={classes.papers}>
                <RemoteList
                  endpoint={APIEndpoints.POSTS.MOST_REACTED}
                  Skeleton={KOSItem.Skeleton}
                  Item={KOSItem}
                  spacing={3}
                />
              </Paper>
            </Grid>
          </Hidden>
        </Grid>
      </VerticalArragement>
    </BaseScreen>
  );
};

export default withMainLayout(withResolvedSession(Home));
