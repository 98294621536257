import React, { useRef, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "i18n";
import APIEndpoints from "APIEndpoints";
import { useResource } from "hooks";
import { arrayToCSV } from "utils";

import TopicArragement from "./TopicArragement";
import Paginator from "./Paginator";
import DeboucedInput from "./DeboucedInput";
import FeedItem from "./Home/FeedItem";
import HorizontalArragement from "./HorizontalArragement";

const PostByCategory = () => {
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [search, setSearch] = useState("");
  const listRef = useRef();
  const { data: topics, loading } = useResource(
    APIEndpoints.GLOBALS.GET_TOPICS,
    { options: { revalidate: false } },
  );
  const { t } = useTranslation();

  if (loading) return <Box />;

  const handleClick = (topic) => {
    if (selectedTopics.includes(topic)) {
      setSelectedTopics(selectedTopics.filter((t) => t !== topic));
    } else setSelectedTopics([...selectedTopics, topic]);
    listRef.current?.refresh();
  };
  const handleNewSearch = (newSearch) => {
    listRef?.current?.refresh();
    setSearch(newSearch);
  };

  return (
    <Grid item xs={12} sm={8} md={8}>
      <Typography variant="h6" paragraph>
        {t("LATEST_POSTS_TITLE")}
      </Typography>
      <DeboucedInput name={t("SEARCH")} onChangeSearch={handleNewSearch} />
      <HorizontalArragement mb={2} px={2} maxWidth="100%">
        <TopicArragement
          chipMargen={1}
          justifyContent="center"
          topics={topics}
          onClick={handleClick}
          variant="outlined"
          selectedValues={selectedTopics}
        />
      </HorizontalArragement>
      <Paginator
        endpoint={APIEndpoints.POSTS.GET_FEED}
        Skeleton={FeedItem.Skeleton}
        Item={FeedItem}
        ref={listRef}
        spacing={5}
        paginated
        withSearch
        search={search}
        topics={arrayToCSV(selectedTopics)}
        withContainer
        limit={10}
      />
    </Grid>
  );
};

export default PostByCategory;
