/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, InputBase, makeStyles, Paper } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { capitalize, debounce } from "lodash";
import React, { useCallback, useState } from "react";

const useStyles = makeStyles((theme) => ({
  searchBar: {
    width: "100%",
    paddingLeft: theme.spacing(1),
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: theme.spacing(45),
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(2),
  },
  iconButton: {
    padding: 10,
  },
}));

const DeboucedInput = ({ name, label, onChangeSearch }) => {
  const classes = useStyles();
  const [search, setSearch] = useState("");

  const debouncedChangeHandler = useCallback(debounce(onChangeSearch, 600), [
    onChangeSearch,
  ]);

  const handleChange = (event) => {
    event.preventDefault();
    setSearch(event.target.value);
    debouncedChangeHandler(event.target.value);
  };

  return (
    <Paper elevation={3} className={classes.root}>
      <InputBase
        className={classes.searchBar}
        id={name}
        name={name}
        value={search}
        placeholder={name}
        onChange={handleChange}
        label={label ?? capitalize(name)}
      />
      <IconButton className={classes.iconButton} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

export default DeboucedInput;
