/* eslint-disable react/prop-types */
import React, { useState, useMemo, useCallback, forwardRef, useRef, useImperativeHandle } from "react";
import { Pagination } from "@material-ui/lab";
import { makeStyles, Paper } from "@material-ui/core";

import RemoteList from "./RemoteList";

const useStyles = makeStyles((theme) => ({
  papers: {
    padding: theme.spacing(2),
  },
}));

const Paginator = (props, ref) => {
  const {
    endpoint,
    limit = 2,
    withSearch,
    withContainer,
    search,
    topics,
    ...rest
  } = props;
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const classes = useStyles();
  const listRef = useRef();
  const aggregatedEndpoint = useMemo(() => ({
    ...endpoint,
    url: `${endpoint.url}?page=${page - 1}&limit=${limit}&search=${search}&topics=${topics}`,
  }), [endpoint, page, limit, search, topics]);

  const onChange = useCallback((data) => setPages(Math.ceil(data.count / limit)), [limit]);

  useImperativeHandle(ref, () => ({
    refresh: () => listRef.current.refresh(),
  }));

  return (
    <>
      {withContainer
        ? (
          <Paper className={classes.papers}>
            <RemoteList
              ref={listRef}
              endpoint={aggregatedEndpoint}
              onChange={onChange}
              paginated
              {...rest}
            />
            <Pagination
              count={pages}
              page={page}
              onChange={(e, val) => setPage(val)}
              hidePrevButton
              shape="rounded"
            />
          </Paper>
        )
        : (
          <>
            <RemoteList
              ref={listRef}
              endpoint={aggregatedEndpoint}
              onChange={onChange}
              paginated
              {...rest}
            />
            <Pagination
              count={pages}
              page={page}
              onChange={(e, val) => setPage(val)}
              hidePrevButton
              shape="rounded"
            />
          </>
        )}

    </>
  );
};

export default forwardRef(Paginator);
